<template>
  <div class="container">
    <div class="row" v-if="nftData !== null">
      <div class="col-md-3">
        <img v-bind:src="nftData.metadata.image" class="img-fluid" style="border-radius: 10px;" />
        <p v-if="typeof nftData.metadata.rarify_score !== 'undefined'" style="text-align: center; margin: 10px 10px" >
          <img src="@/assets/rarify-icon.png" /> Rarify Score: {{ nftData.metadata.rarify_score }} / 23
        </p>
      </div>
      <div class="col-md-9">
        <h3>{{ nftData.metadata.name }} #{{ nftData.tokenId }}</h3>
        Current location: {{ nftData.location.name }}
        <img v-if="nftData.location.short === 'bch'" src="@/assets/bitcoin-cash-bch-logo.png" width="16" height="16" />
        <img v-if="nftData.location.short === 'polygon'" src="@/assets/polygon-matic-logo.png" width="16" height="16" />
        <img v-if="nftData.location.short === 'bnb'" src="@/assets/bnb-bnb-logo.png" width="16" height="16" />
        <img v-if="nftData.location.short === 'avax'" src="@/assets/avalanche-avax-logo.png" width="16" height="16" />
        <img v-if="nftData.location.short === 'ftm'" src="@/assets/fantom-ftm-logo.png" width="16" height="16" />

        <p>
          Contract address: <code>{{ nftData.location.contract }}</code>
        </p>

        <strong>Marketplaces</strong><br/>
        <div v-for="market in nftData.location.markets" :key="market.url">
          <div v-if="market.name === 'oasis'">
            <a v-bind:href="'https://oasis.cash/collection/' + nftData.location.contract" target="blank">OASIS.cash</a>
          </div>
          <div v-if="market.name === 'campfire'">
            <a v-bind:href="market.url" target="_blank">Campfire.exchange</a>
          </div>
          <div v-if="market.name === 'opensea'">
            <a v-bind:href="market.url" target="blank">Opensea.io</a>
          </div>
          <div v-if="market.name === 'tofunft'">
            <a v-bind:href="market.url" target="blank">Tofu NFT</a>
          </div>
        </div>
        <br/>
        <br/>

        <template v-if="showLoader === null">
          <div>
            <template v-for="net in networkInfo.networks" :key="net.chain">
              <button style="margin: 0.3em" v-if="net.visible && $store.state.currentNetwork.short !== net.short" @click="traverse(nftData, net, false)" class="btn btn-outline-primary">
                Send to {{ net.name }}
                <img v-if="net.short === 'bch'" src="@/assets/bitcoin-cash-bch-logo.png" width="16" height="16" />
                <img v-if="net.short === 'polygon'" src="@/assets/polygon-matic-logo.png" width="16" height="16" />
                <img v-if="net.short === 'bnb'" src="@/assets/bnb-bnb-logo.png" width="16" height="16" />
                <img v-if="net.short === 'avax'" src="@/assets/avalanche-avax-logo.png" width="16" height="16" />
                <img v-if="net.short === 'ftm'" src="@/assets/fantom-ftm-logo.png" width="16" height="16" />
              </button>
            </template>
          </div>
          <div>
            <template v-for="net in networkInfo.networks" :key="net.chain">
              <button style="margin: 0.3em" v-if="net.visible && net.lzTraverseId && $store.state.currentNetwork.lzTraverseId && $store.state.currentNetwork.short !== net.short" @click="traverse(nftData, net, true)" class="btn btn-outline-primary">
                Send to {{ net.name }} (LayerZero)
                <img v-if="net.short === 'bch'" src="@/assets/bitcoin-cash-bch-logo.png" width="16" height="16" />
                <img v-if="net.short === 'polygon'" src="@/assets/polygon-matic-logo.png" width="16" height="16" />
                <img v-if="net.short === 'bnb'" src="@/assets/bnb-bnb-logo.png" width="16" height="16" />
                <img v-if="net.short === 'avax'" src="@/assets/avalanche-avax-logo.png" width="16" height="16" />
                <img v-if="net.short === 'ftm'" src="@/assets/fantom-ftm-logo.png" width="16" height="16" />
              </button>
            </template>
          </div>
        </template>
        <template v-if="showLoader && succeeded === null">
          <div>
            <img v-bind:src="loader" style="margin: 0.5em;"/>
          </div>
        </template>
        <template v-if="succeeded === true">
          <br/>
          <div class="card">
            <div class="card-body">
              <h3>Your NFT has been sent!</h3>
              <p>
                It will appear in your other wallet soon. For now it will disappear from your view, don't worry!<br/>
                Usually NFTs arrive within one minute, unless there's a queue. If it takes more than 30 minutes, let us know on Telegram.<br/>
              </p>
            </div>
          </div>
        </template>
        <div class="alert alert-danger" v-if="error">
          {{error}}
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row" v-if="$store.state.metamask.connected">
      <div class="d-flex justify-content-end">
        <button id="reloadWallet" class="btn btn-outline-secondary" @click="loadWallet()">refresh ⟲</button>
      </div>
      <div v-if="!walletLoaded" class="text-center">
        <img v-bind:src="loader" style="margin: 0.5em;" />
      </div>
    </div>
    <div class="row d-flex" v-if="walletNFTs.length == 0 && walletLoaded">
      <div class="text-center">No Waifu $BCH found on {{ currentNetwork.name }}.</div>
    </div>
    <div class="row d-flex" v-if="walletNFTs.length > 0">
      <template v-for="nft in walletNFTs" :key="nft.tokenId">
        <div v-if="nft.location.short === $store.state.network" @click="viewNft(nft)" v-bind:class="'col-lg-2 col-md-3 col-sm-6 nft-card'" style="margin: 0.5em 0px;">
          <div class="card text-center">
            <div class="card-body">
              <p class="card-text">
                <img v-bind:src="nft.metadata.image" class="img-fluid nft-image" style="border-radius: 5px; "/>
                {{ nft.metadata.name }} #{{ nft.tokenId }}
                <img src="@/assets/bitcoin-cash-bch-logo.png" width="16" height="16" v-if="nft.location.short === 'bch'" />
                <img src="@/assets/polygon-matic-logo.png" width="16" height="16" v-if="nft.location.short === 'polygon'" />
                <img src="@/assets/bnb-bnb-logo.png" width="16" height="16" v-if="nft.location.short === 'bnb'" />
                <img src="@/assets/avalanche-avax-logo.png" width="16" height="16" v-if="nft.location.short === 'avax'" />
              </p>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import {BigNumber, ethers} from "ethers";
import contractInfo from "../../artifacts/contracts/Waifu.sol/WaifuFaucet.json";
import netInfo from "../../config/networks.json";
import {loader} from "@/main";

export default {
  data() {
    return {
      walletNFTs: [],
      nftData: null,
      nftMetadata: [],
      networkInfo: netInfo,
      loader: loader,
      showLoader: null,
      succeeded: null,
      walletLoaded: true,
      gas: [],
      error: null,
    }
  },
  mounted() {
    this.loadWallet();
    fetch('/gas.json').then((results) => {
      return results.json();
    }).then((gas) => {
      this.gas = gas;
    });
  },
  computed: {
    address() {
      return this.$store.state.metamask.address;
    },
    currentNetwork() {
      return this.$store.state.currentNetwork;
    }
  },
  watch: {
    address() {
      this.loadWallet();
    },
    currentNetwork() {
      this.nftData = null;
      this.loadWallet();
    }
  },
  methods: {
    viewNft: function(data) {
      this.nftData = data;
      this.showLoader = null;
      this.succeeded = null;
      this.error = null;

      window.scrollTo(0, 0);
    },
    loadWallet: function() {
      let provider = new ethers.providers.JsonRpcProvider(this.$store.state.currentNetwork.rpc, this.$store.state.currentNetwork.chain);
      let contract = new ethers.Contract(this.$store.state.currentNetwork.contract, contractInfo.abi, provider);
      let loadingNetwork = this.$store.state.currentNetwork;
      this.walletNFTs = [];
      this.walletLoaded = false;

      contract.balanceOf(this.$store.state.metamask.address).then((wallet) => {
        if(wallet === 0) {
          this.walletLoaded = true;
        } else {
          for(var i = 0;i < wallet;i++) {
            contract.tokenOfOwnerByIndex(this.$store.state.metamask.address, i).then((token) => {
              fetch('https://raw.githubusercontent.com/shadowkite/waifu/master/json/' + token.toString() + '.json').then((response) => {
                return response.json();
              }).then((metadata) => {
                this.walletNFTs.push({
                  tokenId: token,
                  location: loadingNetwork,
                  metadata: metadata
                });

                if (this.walletNFTs.length >= wallet) {
                  this.walletLoaded = true;
                }
              });
            });
          }
        }
      });
    },
    traverse: async function(nftData, network, lz) {
      this.error = null;
      try {
        let provider = new ethers.providers.Web3Provider(window.ethereum);
        let net = await provider.getNetwork();
        if (nftData.location.chain !== net.chainId) {
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{chainId: ethers.utils.hexValue(nftData.location.chain)}]
          });
        }
        provider = new ethers.providers.Web3Provider(window.ethereum);
        let contract = new ethers.Contract(this.currentNetwork.contract, contractInfo.abi, provider.getSigner());
        if (!lz) {
          let gas = 0;
          for (var row in this.gas) {
            if (this.gas[row].from === nftData.location.traverseId && this.gas[row].to === network.traverseId) {
              gas = BigNumber.from(this.gas[row].gas);
            }
          }
          if (gas === 0) {
            throw 'Could not get gas';
          }

          let minGas = await contract.gasCosts(network.traverseId);
          if(minGas.gt(gas)) {
            gas = minGas + 1;
          }

          try {
            let tx = await contract.projectTraverseChains(network.traverseId, nftData.tokenId, {value: gas});
            this.showLoader = true;
            await tx.wait();
            this.succeeded = true;
            this.showLoader = false;
          } catch(e) {
            if(e.data) {
              this.error = e.data.message;
            } else {
              this.error = e.message
            }
          }
        } else {
          let payload = ethers.utils.solidityPack(['address', 'uint256'], [this.$store.state.metamask.address, nftData.tokenId]);
          let calldata = ethers.utils.solidityPack(['uint16', 'uint256'], [1, 350000]);
          let fees = await contract.estimateFees(
              network.lzTraverseId,
              network.contract,
              payload,
              false,
              calldata
          );
          try {
            let tx = await contract.traverseChains(network.lzTraverseId, nftData.tokenId, {value: (fees.nativeFee + 1)});
            this.showLoader = true;
            await tx.wait();
            this.succeeded = true;
            this.showLoader = false;
          } catch(e) {
            if(e.data) {
              this.error = e.data.message;
            } else {
              this.error = e.message
            }
          }
        }
      } catch(e) {
        console.log('Error caught');
        this.error = e.message;
      }
    },
  }
}
</script>

<style scoped lang="scss">
div.nft-card:hover {
  cursor: pointer;
  filter: drop-shadow(3px 3px 3px #CCC);
  text-decoration: underline;
}
</style>